import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../layouts'
import SEO from '../components/SEO'
import PostList from '../components/PostList'
import Container from '../components/Container'

class BlogListPage extends React.Component {
  render () {
    const posts = get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <Layout location={this.props.location} siteMeta={this.props.data.site.siteMetadata}>
        <SEO
          title={this.props.data.site.siteMetadata.name + ' Blog'}
          description="Base64 Decoder Blog. Learn all about Base64 Decoding. What is Base64 decoding, How to Base64 encode and decode, Why Base64 encoding and decoding is required, and How to perform Base64 decoding in different programming languages like Java, Python, Javascript, Golang, and PHP."
          pathname={this.props.location.pathname}
          article={false}
        />
        <Container>
          <PostList posts={posts} siteMeta={this.props.data.site.siteMetadata} />
        </Container>
      </Layout>
    )
  }
}

export default BlogListPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "post" } } }
    ) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
            description
            featured_image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 768) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
